import React from 'react';
import { createElement, forwardRef } from 'react';
import { Badge } from '../Badge';
import { OverridableComponent, OverridableComponentProps } from 'types/OverridableComponent';

export type TabTypeMap = {
  props: {
    controlsIndex?: number;
    active?: boolean;
    notificationCount?: number;
  };
  defaultComponent: 'a';
};

export type TabProps = OverridableComponentProps<TabTypeMap>;
export type TabType = OverridableComponent<TabTypeMap>;

export const Tab: TabType = forwardRef<HTMLAnchorElement, TabProps>(
  ({ component = 'a', children, controlsIndex, active, notificationCount, className = '', ...props }, ref) => {
    const cssClasses = className.split(' ').concat(['tab']);

    if (active) cssClasses.push('active');

    const accessibilityProps = { 'aria-controls': controlsIndex !== undefined ? `${controlsIndex}-tabpanel` : undefined };

    return createElement(component, { 
      ref: ref, 
      className: cssClasses.join(' ').trim(), 
      ...accessibilityProps, 
      ...props,
      children: [children, <Badge key='badge' color='bg-accent' count={notificationCount} />] //Append the notification badge to the children
    });
  }
);
